import {createStore, applyMiddleware, Store, AnyAction} from 'redux';
import {composeWithDevTools} from '@redux-devtools/extension';
import {createWrapper, MakeStore} from 'next-redux-wrapper';
import rootReducer from './root-reducer';
import middlewares from '../middlewares';

// Add redux dev tools
const enhancer = composeWithDevTools(applyMiddleware(...middlewares));

/**
 * Create redux store
 *
 * @param   {Context}  context
 *
 * @return  {Store}
 */
// create a makeStore function
let store: Store<unknown, AnyAction>;
const makeStore: MakeStore = () => {
  store = createStore(rootReducer, enhancer);
  return store;
};

export {store};
// export an assembled wrapper
export const reduxWrapper = createWrapper(makeStore);
